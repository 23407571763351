import React,{useEffect,useState} from 'react'
import Link from '../components/gatsbylink'
import {

  Box,
  Text,
  Heading,
  useColorMode,
  SimpleGrid,
} from '@chakra-ui/react'
import mytext from './judtext.json'
import { motion } from 'framer-motion'
 import TonDokumente from '../components/tondokumente'
 import MeinSeo from '../components/seo'


//  function BasicUsage() {
//   const { isOpen, onOpen, onClose } = useDisclosure()
//   return (
//     <>
//       <Button my="4" onClick={onOpen}>Die Namensliste  auf einen Blick</Button>

//       <Modal isOpen={isOpen} onClose={onClose}>
//         <ModalOverlay />
//         <ModalContent>
//           <ModalHeader>Modal Title</ModalHeader>
//           <ModalCloseButton />
//           <ModalBody>
//             Hldlsfk asödkf 
//           </ModalBody>

//           <ModalFooter>
//             <Button colorScheme='blue' mr={3} onClick={onClose}>
//               Close
//             </Button>
//             <Button variant='ghost'>Secondary Action</Button>
//           </ModalFooter>
//         </ModalContent>
//       </Modal>
//     </>
//   )
// }



// const MText=({alle})=>{
// console.log(alle)

//   return(
//     <Box  width="100%">
//     {alle}
//     </Box> 
//   )
// }


const  GedenkListe = () => {
  const cm=useColorMode()
const mtt = mytext.mytext
const mt = mtt.replace(/&nbsp;/g," ")
const pers= mt.split('|')
const [person]=useState(pers)
const [ind,setIndex]=useState(0)
const [alle,setAlle] = useState("")





useEffect(()=>{
  if(ind>=person.length) return
  const timer=setTimeout(()=>{
    let p = person[ind].split(":")
    setAlle("| "+ p[0]+" - "+p[1]+" "+alle)
    setIndex(ind+1)
    
  },230)
  return () => clearTimeout(timer);
},[ind,alle,person])



 
return (
 

    <motion.div
    initial={{opacity:0,scale:0.9,paddingBottom:"100px"}}
    animate={{opacity:1,scale:1}}
    transition={{duration:.7,ease:"easeInOut"}}
    >
    <>
    <MeinSeo title="Dokumentation der bekannten jüdischen Opfer" 
    description='Tondokumente und Animation zu den jüdischen Opfern'
    />
     <Heading variant="Titel" pt="9">Verfolgte und ermordete Juden im Hunsrück und am Mittelrhein</Heading>
    <Box>
     
      <TonDokumente /> 

    
    
             </Box> 
    {/* <Flex  my="9" flexDirection={["column","column","column"]} justifyContent="space-around">  */}
    <SimpleGrid columns={[1,2]} gap={5}>
    {/* </motion.div> */}
      <Box my={[2,7]} >
              <Text variant='solid'>Nur wenige Spuren bezeugen heute den Verlust der jüdischen Gemeinden und ihrer Mitglieder. Vor allem den Jüngeren gelang die Flucht, die meisten Älteren wurden deportiert, in den Konzentrationslagern ermordet oder blieben verschollen.
          Sie alle wurden beraubt und ausgeplündert. 
          </Text>
          <Text variant='solid'>
          Vereinzelt erinnern persönliche Gegenstände, Briefe und Erzählungen an individuelle Schicksale, die in Markt 1 im Streiflicht präsentiert werden. Ausgewählte Fragmente beleuchten die brutale Wendung der Lebensgeschichten. Dokumentiert werden darüber hinaus die Namen und – sofern vorhanden – biografischen Angaben der Opfer der nationalsozialistischen Judenverfolgung aus dem Rhein-Hunsrück-Kreis, Oberwesel und Bacharach. 
          </Text>
          
          <Link to="/bacharach"><Text _hover="blue.200" variant='solid' color="blue.400">&rarr; Bacharacher Schicksale</Text></Link>
          <Link to="/oberwesel"><Text _hover="blue.200" variant='solid' color="blue.400">&rarr; Oberweseler Schicksale mit biographischen Skizzen</Text></Link>

          <Text variant='solid'>
          Eine multimediale Installation verbindet Namen, Bilder, Zeugenzitate und eröffnet somit einen Raum zum Gedenken
          </Text>
          <Text variant="solid">
         Der Mord an den europäischen Juden hat viele Bezeichnungen. Im englischsprachigen Raum nennt man ihn Holocaust (gr. „Brandopfer“), im Hebräischen Shoah („Katastrophe“) oder auch Churban („Verwüstung, Vernichtung“).
     <br/>
     Diese Liste enthält die Namen der Opfer, die im heutigen Rhein-Hunsrück-Kreis bekannt sind. Darüber hinaus haben wir Menschen aus angrenzenden Dörfern des Hunsrücks aufgenommen, die in der Hunsrückregion geboren wurden, dort zumindest zeitweise gelebt haben oder auch mit Hunsrückern verheiratet waren. 
     </Text>
       
    
       <Text variant="solid">
       Trotz aller Sorgfalt bleiben viele Lücken, Unzulänglichkeiten, Verwechslungen und Schreibfehler. Wir möchten aber trotzdem den Opfern wieder einen Namen geben, den die Nationalsozialisten tilgen wollten.
       </Text>
       <Text variant="solid" color="white" pt="0">
       Sie sind ein Zeichen gegen das Vergessen und eine Mahnung für Gegenwart und Zukunft.
       </Text>
       <Text variant="solid"  >
  Liste erstellt von Carolin Manns &amp; Ida Faust
  (Mitarbeiterinnen des Forst-Mayer Studien- und Begegnungszentrums in Laufersweiler)
  </Text>
          
     </Box>
   
  
  
 
     
  
     <Box my={[2,10]}>
 
  <Text pl="0" py="0" fontSize=".9rem" color="teal.500">unvollständige Liste<br/> der Verfolgten und Ermordeten</Text>
          <Box pt="3">
            <Text color="red.500" fontSize="2xl">{ind}</Text>
            <Text color="blue" fontSize="lg" py="2">{person[ind]}</Text>
          </Box>
          <Box minHeight="50vh"><Text px="0">{alle}</Text>
          <Box  maxWidth="800" bg={cm.colorMode==="dark"?"gray.700":"whiteAlpha.900"} 
       >


</Box>
          </Box>
            </Box>
         
        </SimpleGrid> 
       
  
  </>   
</motion.div>
)

}

export default GedenkListe

