import React from 'react'
import Player from './player'
import {Box,Text,Flex} from '@chakra-ui/react'


const TonDokumente =()=>{
return(
    <Flex direction={["column","column","row"]} mt="10">
   
    <Box px="2" py="2" borderLeft="4px gray solid">
    <Player url="/audio/Reichspogromnacht.mp3" />
        <Text color="text">
            Reichspogromnacht
        </Text>
       
       
    </Box> 
    <Box px="2" pb="2" borderLeft="4px gray solid" >
    <Player url="/audio/jud1audio.mp3" />
        <Text color="text">
            Erinnerungen von Überlebenden<br/>
           
        </Text>
        
       
    </Box>

    <Box px="2" pb="2" borderLeft="4px gray solid">
    <Player url="/audio/interviewg.mp3" />
        <Text color="text">
            Interview mit Inge Ariel Grünewald <br/>
            Tondokument
        </Text>
       
       
    </Box>
    <Box px="2" pb="2" borderLeft="4px gray solid">
    <Player url="/audio/wiltrud_hobbach.mp3" />
        <Text color="text">
            Interview mit Wiltrud Hobbach <br/>
            Tondokument
        </Text>
       
       
    </Box>
    <Box px="2" pb="2" borderLeft="4px gray solid">
    <Player url="/audio/AlfredGottschalk.mp3" />
        <Text color="text">
            Interview mit Alfred Gottschalk <br/>
            Tondokument
        </Text>
       
       
    </Box>
 </Flex>

)
}
export default TonDokumente