import React from "react";
import { useAudio } from "../hooks/use-audio"
import { Badge } from "@chakra-ui/react";
import {MdPlayCircle,MdStop} from 'react-icons/md'

const Player = ({ url }) => {
    const [playing, toggle] = useAudio(url);
    

    return (
        
   
       <Badge cursor="pointer" onClick={toggle}>{playing ? <MdStop fontSize="24px" background="white" /> : <MdPlayCircle fontSize="24px" />}</Badge>
      
    );
  }; 
  export default Player